import { Page } from 'components/layout';
import React from 'react';
import { DataTable } from 'components/common/DataTable';
import Button from '@ingka/button';
import styled from 'styled-components';
import { AddCustomerFormModal, EditCustomerFormModal } from 'pages/Customers/CustomerFormInput';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { customersAPI } from 'api/customersAPI';
import { refetchCustomersQueryMutationOption } from 'pages/Customers/refetchCustomersOption';
import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { useToast } from 'components/ToastAnchor';
import { CustomerDto } from 'api/genTypes/customer';

const DeleteButton = (props: { customer: CustomerDto }) => {
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(
    customersAPI.delete,
    refetchCustomersQueryMutationOption(queryClient)
  );
  const appInsights = useAppInsightsContext();
  const trackCustomerDeleted = useTrackEvent(appInsights, 'Customer Deleted', {});
  const toast = useToast();

  return (
    <Button
      size="small"
      type="tertiary"
      onClick={() =>
        deleteMutation
          .mutateAsync(props.customer)
          .then(() => trackCustomerDeleted(props.customer))
          .catch((err: Error) => toast.open(err.message, 'ERROR'))
      }
      loading={deleteMutation.isLoading}
    >
      Delete
    </Button>
  );
};

const PageTitle = styled.h2`
  text-align: center;
`;

const CenterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0 48px;
`;

const customerTableColumnConfig = [
  {
    header: 'Customers',
    columns: [
      {
        header: 'First name',
        accessorKey: 'firstName',
      },
      {
        header: 'Last name',
        accessorKey: 'lastName',
      },
      {
        header: 'Email address',
        accessorKey: 'emailAddress',
      },
      {
        header: 'Phone number',
        accessorKey: 'phoneNumber',
      },
      {
        header: ' ',
        cell: ({ row }: { row: { original: CustomerDto } }) => (
          <div>
            <EditCustomerFormModal customer={row.original} />
            <DeleteButton customer={row.original} />
          </div>
        ),
      },
    ],
  },
];

const CustomersPage = () => {
  const customers = useQuery('FETCH_CUSTOMERS_QUERY', customersAPI.fetchAll, {
    refetchOnWindowFocus: false,
  });

  const content = customers.isError ? (
    <div>Error</div>
  ) : customers.isLoading ? (
    <div>Fetching ...</div>
  ) : (
    <DataTable columns={customerTableColumnConfig} data={customers.data} />
  );

  return (
    <Page>
      <PageTitle>Customers</PageTitle>
      <CenterContainer>{content}</CenterContainer>
      <CenterContainer>
        <AddCustomerFormModal />
      </CenterContainer>
    </Page>
  );
};
export default CustomersPage;
