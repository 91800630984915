import Text from '@ingka/text';
import { appConfig } from 'appConfig';
import { Stack, Page } from 'components/layout';
import React from 'react';

export const AboutPage = () => (
  <Page>
    <Stack>
      <Text bodySize="l">Hej, welcome to {appConfig.appName}!</Text>
    </Stack>
  </Page>
);
