import { useEffect, useState } from 'react';
import lF from 'localforage';

export const useDisclosure = (initialIsOpen: boolean = false) => {
  const [isOpen, setIsOpen] = useState(initialIsOpen);
  return {
    isOpen,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
    togle: () => setIsOpen((x) => !x),
  };
};

export const useIsMobileByScreenSize = (maxWidth: number = 767) => {
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    setIsMobile(window.matchMedia(`(max-width: ${maxWidth}px)`).matches);
  }, [maxWidth]);
  return isMobile;
};

const setFile =
  (f: File) =>
  (fs: Map<string, File>): Map<string, File> => {
    const newFs = new Map(fs);
    return newFs.set(f.name, f);
  };

const deleteFile =
  (f: File) =>
  (fs: Map<string, File>): Map<string, File> => {
    const newFs = new Map(fs);
    newFs.delete(f.name);
    return newFs;
  };

export const useLocalForgeFiles = () => {
  const [storedFiles, setStoredFiles] = useState<Map<string, File>>(new Map());
  const localForage = lF.createInstance({ name: 'CachedImageFiles' });

  useEffect(() => {
    setStoredFiles(new Map());
    localForage.keys().then(async (ks: Array<string>) => {
      for (const k of ks) {
        const f = await localForage.getItem<File>(k);
        setStoredFiles(setFile(f));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    files: Array.from(storedFiles.values()).reverse(),
    storeFile: (f: File) =>
      localForage
        .setItem(f.name, f)
        .then(() => setStoredFiles(setFile(f)))
        .catch(window.alert),
    removeFile: (f: File) =>
      localForage.removeItem(f.name).then(() => setStoredFiles(deleteFile(f))),
  };
};

export const useAsyncCall = <T>(asyncF: () => Promise<T>): [boolean, () => Promise<T>] => {
  const [isLoading, setIsLoading] = useState(false);
  return [
    isLoading,
    async (): Promise<T> => {
      setIsLoading(true);
      return asyncF().then((result: T) => {
        setIsLoading(false);
        return result;
      });
    },
  ];
};
