import { configuredAxios } from 'api/configuredAxios';
import { appConfig } from 'appConfig';
import { CustomerDto } from 'api/genTypes/customer';

export const customersAPI = {
  fetchAll: () =>
    configuredAxios
      .get<Array<CustomerDto>>(appConfig.customerEndpoint)
      .then((result) => result.data),
  create: (c: Omit<CustomerDto, 'id'>) => configuredAxios.post(appConfig.customerEndpoint, c),
  delete: (c: CustomerDto) => configuredAxios.delete(appConfig.customerEndpoint + `/${c.id}`),
  update: (c: CustomerDto) => configuredAxios.put(appConfig.customerEndpoint + `/${c.id}`, c),
};
