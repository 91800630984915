import { appConfig } from 'appConfig';
import axios from 'axios';
import { acquireAccessToken } from 'api/aquireAccessToken';
import { useToast } from 'components/ToastAnchor';

const configuredAxios = axios.create();
configuredAxios.defaults.headers.post['Content-Type'] = 'application/json';

configuredAxios.interceptors.request.use(
  async (config) => {
    const token = await acquireAccessToken(appConfig.appScope.split(','));
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    useToast.getState().open('Token Fetch Interceptor', 'ERROR');
    Promise.reject(error);
  }
);

export { configuredAxios };
